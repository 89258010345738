/**
 * Our mini-cart-state
 */
import React from 'react';

// Context
const State = React.createContext();
const Dispatch = React.createContext();

// Reducer
const reducer = (state, language) => language;

const Provider = ({children}) => {
    const [state, dispatch] = React.useReducer(reducer, 'de');

    return (<State.Provider value={state}>
        <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>);
};

// Export
export const CurrentLanguage = {
    State,
    Dispatch,
    Provider,
};
