/**
 * our world card state
 *
 * @author max.
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';

// Context
const State = React.createContext();
const Dispatch = React.createContext();

// Reducer
const reducer = (state, volume) => volume;

const Provider = ({children}) => {
    const [state, dispatch] = React.useReducer(reducer, null);

    return (<State.Provider value={state}>
        <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>);
};

// Export
export const CurrentVolume = {
    State,
    Dispatch,
    Provider,
};
