/**
 * Our mini-cart-state
 */
import React, {useContext, useEffect, useState} from 'react';
import {io} from 'socket.io-client';
import {CurrentLanguage} from './CurrentLanguage';
import {globalHistory} from '@reach/router';

// Context
const State = React.createContext();

// Reducer
const Provider = ({children}) => {
    const [isConnected, setIsConnected] = useState(false);
    const [socket, setSocket] = useState(null);
    const [room, setRoom] = useState(null);

    const switchLanguage = useContext(CurrentLanguage.Dispatch);

    const joinRoom = () => {
        if (!socket || !room) {
            return;
        }

        if ('guide-app/' === room) {
            setIsConnected(true);
            return;
        }

        socket.emit('join', room);
        setIsConnected(true);
        console.log(`socket.io: Joining room ${room}`);
    };

    const reloadRoom = () => {
        setRoom(window.location.pathname.split('/')[1]?.startsWith('guide')
            ? `guide-app/${window.location.pathname.split('/')[2]}`
            : window.location.pathname.split('/')[1]);
    };

    globalHistory.listen(reloadRoom);
    useEffect(joinRoom, [room]);

    const onConnect = () => {
        console.log(`socket.io: Connected to Server at ${process.env.GATSBY_SOCKET_IO}`);
        reloadRoom();
    };

    useEffect(() => {
        if (!socket) {
            console.log('socket.io: No socket given in main class!');
            return;
        }

        // Connection events
        socket.on('connect', onConnect);
        socket.on('disconnect', () => {
            console.log('socket.io: Disconnected');
            setIsConnected(false);
        });
        socket.on('connect_error', () => console.log(`socket.io: Could not connect to socket.io-server at ${process.env.GATSBY_SOCKET_IO}`));

        // Default Events
        socket.on('switchLang', lang => {
            console.log(`socket.io told me to switch the language to ${lang}`);
            switchLanguage(lang);
        });

        socket.on('refresh', () => {
            console.log('socket.io told me to reload');
            window.location.reload();
        });

        return () => {
            if (!socket || !room || 'guide-app/' === room) {
                return;
            }
            console.log(`socket.io: Leaving room: ${room}`);
            socket.emit('leave', room);
        };
    }, [socket, room]);

    useEffect(() => {
        if (!process.env.GATSBY_SOCKET_IO || '/' === window.location.pathname || '/guide-app' === window.location.pathname) {
            console.log('socket.io: No url or room given');
            return;
        }

        // Init the connection
        console.log('socket.io: Creating socket');
        const ioSocket = io(process.env.GATSBY_SOCKET_IO);
        setSocket(ioSocket);


        return () => {
            ioSocket.disconnect();
        };
    }, []);

    return (<State.Provider value={{isConnected, socket, room}}>
        {children}
    </State.Provider>);
};

// Export
export const SocketIO = {
    State,
    Provider,
};
