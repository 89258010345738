/**
 * Our global data/state-store
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 *
 * https://medium.com/@samuelresua/global-state-with-react-context-cfa99946900d
 */
import React, {cloneElement} from 'react';
import {CurrentLanguage} from './Providers/CurrentLanguage';
import {CurrentBackground} from './Providers/CurrentBackground';
import {WorldCard} from './Providers/WorldCard';
import {SocketIO} from './Providers/SocketIO';
import {CurrentVolume} from './Providers/CurrentVolume';

// Collecting the providers
const providers = [
    <SocketIO.Provider key={0}/>, // Needs to be first
    <CurrentLanguage.Provider key={1}/>,
    <CurrentBackground.Provider key={2}/>,
];

// Store-Wrapper
const Store = ({children: initial}) => providers.reduce((children, parent, key) => cloneElement(
    parent,
    {children, key},
), initial);

// Export everything
export {
    Store,
    SocketIO,
    CurrentLanguage,
    CurrentBackground,
    WorldCard,
    CurrentVolume,
};
