exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-guide-app-js": () => import("./../../../src/pages/guide-app.js" /* webpackChunkName: "component---src-pages-guide-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-static-battery-advisor-js": () => import("./../../../src/pages/static/battery-advisor.js" /* webpackChunkName: "component---src-pages-static-battery-advisor-js" */),
  "component---src-pages-static-timber-sports-leaderboard-js": () => import("./../../../src/pages/static/timber-sports/leaderboard.js" /* webpackChunkName: "component---src-pages-static-timber-sports-leaderboard-js" */),
  "component---src-pages-static-timber-sports-result-js": () => import("./../../../src/pages/static/timber-sports/result.js" /* webpackChunkName: "component---src-pages-static-timber-sports-result-js" */),
  "component---src-screens-chain-select-data-wrapper-js": () => import("./../../../src/screens/ChainSelect/DataWrapper.js" /* webpackChunkName: "component---src-screens-chain-select-data-wrapper-js" */),
  "component---src-screens-chainsaw-evolution-data-wrapper-js": () => import("./../../../src/screens/ChainsawEvolution/DataWrapper.js" /* webpackChunkName: "component---src-screens-chainsaw-evolution-data-wrapper-js" */),
  "component---src-screens-chronicle-data-wrapper-js": () => import("./../../../src/screens/Chronicle/DataWrapper.js" /* webpackChunkName: "component---src-screens-chronicle-data-wrapper-js" */),
  "component---src-screens-experience-tomorrow-data-wrapper-js": () => import("./../../../src/screens/ExperienceTomorrow/DataWrapper.js" /* webpackChunkName: "component---src-screens-experience-tomorrow-data-wrapper-js" */),
  "component---src-screens-guide-app-display-data-wrapper-js": () => import("./../../../src/screens/GuideAppDisplay/DataWrapper.js" /* webpackChunkName: "component---src-screens-guide-app-display-data-wrapper-js" */),
  "component---src-screens-guide-app-screen-data-wrapper-js": () => import("./../../../src/screens/GuideAppScreen/DataWrapper.js" /* webpackChunkName: "component---src-screens-guide-app-screen-data-wrapper-js" */),
  "component---src-screens-info-screen-data-wrapper-js": () => import("./../../../src/screens/InfoScreen/DataWrapper.js" /* webpackChunkName: "component---src-screens-info-screen-data-wrapper-js" */),
  "component---src-screens-innovation-compass-data-wrapper-js": () => import("./../../../src/screens/InnovationCompass/DataWrapper.js" /* webpackChunkName: "component---src-screens-innovation-compass-data-wrapper-js" */),
  "component---src-screens-location-evolution-data-wrapper-js": () => import("./../../../src/screens/LocationEvolution/DataWrapper.js" /* webpackChunkName: "component---src-screens-location-evolution-data-wrapper-js" */),
  "component---src-screens-quality-slider-data-wrapper-js": () => import("./../../../src/screens/QualitySlider/DataWrapper.js" /* webpackChunkName: "component---src-screens-quality-slider-data-wrapper-js" */),
  "component---src-screens-video-gallery-data-wrapper-js": () => import("./../../../src/screens/VideoGallery/DataWrapper.js" /* webpackChunkName: "component---src-screens-video-gallery-data-wrapper-js" */),
  "component---src-screens-video-gallery-split-view-data-wrapper-js": () => import("./../../../src/screens/VideoGallerySplitView/DataWrapper.js" /* webpackChunkName: "component---src-screens-video-gallery-split-view-data-wrapper-js" */),
  "component---src-screens-wall-of-fame-data-wrapper-js": () => import("./../../../src/screens/WallOfFame/DataWrapper.js" /* webpackChunkName: "component---src-screens-wall-of-fame-data-wrapper-js" */),
  "component---src-screens-water-circle-data-wrapper-js": () => import("./../../../src/screens/WaterCircle/DataWrapper.js" /* webpackChunkName: "component---src-screens-water-circle-data-wrapper-js" */),
  "component---src-screens-world-data-wrapper-js": () => import("./../../../src/screens/World/DataWrapper.js" /* webpackChunkName: "component---src-screens-world-data-wrapper-js" */)
}

